import React from 'react';
import './styles.css'
import { FaBalanceScale, FaBug } from "react-icons/fa";
import { FaAddressCard } from "react-icons/fa6";
import { GiSpy } from "react-icons/gi";
import { PiCellSignalHighBold } from "react-icons/pi";

const Footer = () => {
    return (
        <>
            <div className={"footer"}>
                <div className={"footer-title__container"}>
                    <img src={'/logo_new.png'} alt="logo" className="icon"/>
                    <h1 className={'logo__text'}>
                        HealthMate
                    </h1>
                </div>

                <div className={'footer-main__container'}>
                    <div className={"footer-about"}>
                        <h3>
                            About Us
                        </h3>

                        <div className={"footer-about__links"}>
                            <a href={'/contact-us'}
                               aria-label={'contact-us-link'}> <FaAddressCard fill={'#4093ff'}/> Contact Us </a>
                            <a href={'/report-bugs'}
                               aria-label={'report-bugs-link'}><FaBug
                                fill={'#4093ff'}/> Report Bugs</a>
                        </div>
                    </div>

                    <div className={"footer-legal"}>
                        <h3>
                            Legal
                        </h3>

                        <div className={"footer-legal__links"}>
                            <a href={'/terms'} aria-label={'terms-link'}><FaBalanceScale fill={'#a5a5a5'}/> Terms</a>
                            <a href={'/privacy'} aria-label={'privacy-link'}><GiSpy fill={'#a5a5a5'}/> Privacy Policy</a>
                        </div>
                    </div>
                </div>
            </div>

            <div className={'footer-info'}>
                <p className="footer-text">© 2025 HealthMate. All rights reserved.</p>
                <p className={`status-text`}><span><PiCellSignalHighBold fill={'#4ade80'}/></span> <a className={`status-text__a Online`}>Online</a></p>
            {/*    rel={'noreferrer nofollow noopener'} target={'_blank'}  href={'https://healthmate.instatus.com' */}
            </div>
        </>
    );
};

export default Footer;