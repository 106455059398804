import React, {useState} from 'react';
import './login.css';
import Footer from "../../../components/footer/footer";
import {signInWithEmailAndPassword} from "firebase/auth";
import AuthDetails from "../details/AuthDetails";
import {BsEyeFill, BsEyeSlashFill} from "react-icons/bs";
import { GoogleAuthProvider, getAuth, signInWithPopup, GithubAuthProvider, AuthError, fetchSignInMethodsForEmail } from "firebase/auth";
import {Toaster} from "sonner";
import {useNavigate} from "react-router-dom";
import Navbar from "../../../components/navbar/navbar";
import { FaGithub, FaGoogle, FaChartLine, FaWeightScale } from "react-icons/fa6";
import {IoWarning} from "react-icons/io5";
import BgImages from "../../../components/background/bgImages";
import {FaHeartbeat} from "react-icons/fa";

const AccountLogin = () => {
    const provider = new GoogleAuthProvider();
    const githubProvider = new GithubAuthProvider();
    const navigate = useNavigate();

    const [passwordVisible, setPasswordVisible] = useState<boolean>(false);


    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const [errorMessage, setErrorMessage] = useState<string>('');

    const handleAuthError = (error: AuthError) => {
        let message: string;

        switch (error.code) {
            // Email/Password Errors
            case "auth/invalid-email":
                message = "Invalid email format.";
                break;
            case "auth/user-disabled":
                message = "This user has been disabled.";
                break;
            case "auth/user-not-found":
                message = "No account found with this email.";
                break;
            case "auth/wrong-password":
                message = "Incorrect password.";
                break;
            case "auth/too-many-requests":
                message = "Too many login attempts. Try again later.";
                break;
            case "auth/network-request-failed":
                message = "Network error. Check your connection.";
                break;
            case "auth/internal-error":
                message = "An internal Firebase error occurred. Please try again.";
                break;
            case "auth/invalid-credential":
                message = "Invalid email/password combination.";
                break;

            // Popup-based Authentication Errors
            case "auth/popup-closed-by-user":
                message = "The popup was closed before completing sign-in.";
                break;
            case "auth/cancelled-popup-request":
                message = "Cancelled previous popup request due to a new one.";
                break;
            case "auth/account-exists-with-different-credential":
                message = "This email is already associated with another sign-in method.";
                break;
            case "auth/auth-domain-config-required":
                message = "Firebase authentication domain is not properly configured.";
                break;
            case "auth/operation-not-allowed":
                message = "This sign-in method is not enabled in Firebase settings.";
                break;
            case "auth/timeout":
                message = "Operation timed out. Please try again.";
                break;
            case "auth/invalid-verification-code":
                message = "Invalid verification code for multi-factor authentication.";
                break;
            case "auth/invalid-verification-id":
                message = "Invalid verification ID. Try the verification process again.";
                break;

            // Generic Errors
            case "auth/unauthorized-domain":
                message = "The app is not authorized to use Firebase Authentication.";
                break;
            case "auth/user-token-expired":
                message = "User's credential has expired. Sign in again.";
                break;
            case "auth/web-storage-unsupported":
                message = "This browser does not support web storage needed for authentication.";
                break;
            case "auth/credential-already-in-use":
                message = "This credential is already associated with a different user.";
                break;
            case "auth/requires-recent-login":
                message = "This operation requires a recent login. Please sign in again.";
                break;

            default:
                message = `Authentication error: ${error.message}`;
        }

        setErrorMessage(message);
        console.error(`Error Code: ${error.code} - ${message}`);
    };

    const loginViaGoogle = () => {
        const auth = getAuth();

        signInWithPopup(auth, provider)
            .then(() => {
                setIsButtonDisabled(true);
                navigate("/manage-account");
                setErrorMessage("");
            })
            .catch((error) => handleAuthError(error));
    };

    const loginViaGithub = () => {
        const auth = getAuth();

        signInWithPopup(auth, githubProvider)
            .then(() => {
                setIsButtonDisabled(true);
                navigate("/manage-account");
                setErrorMessage("");
            })
            .catch(async (error) => {
                if (error.code === "auth/account-exists-with-different-credential") {
                    const existingEmail = error.customData?.email;
                    const pendingCredential = GithubAuthProvider.credentialFromError(error);

                    if (existingEmail && pendingCredential) {
                        try {
                            const signInMethods = await fetchSignInMethodsForEmail(auth, existingEmail);

                            if (signInMethods.includes("password")) {
                                setErrorMessage("An account with this email already exists. Please log in using email/password first and link GitHub from account settings.");
                            } else if (signInMethods.includes("google.com")) {
                                setErrorMessage("An account with this email exists via Google. Try logging in with Google.");
                            } else {
                                setErrorMessage("An account exists with another provider. Please use the correct sign-in method.");
                            }
                        } catch (fetchError) {
                            setErrorMessage("Error fetching account details. Please try again.");
                            console.error(fetchError);
                        }
                    } else {
                        setErrorMessage("This email is already linked to another account.");
                    }
                } else {
                    handleAuthError(error);
                }
            });
    };

    const loginSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        setErrorMessage("");

        const auth = getAuth();

        signInWithEmailAndPassword(auth, email, password)
            .then(() => {
                setIsButtonDisabled(true);
                navigate("/manage-account");
                setErrorMessage("");
            })
            .catch((error) => handleAuthError(error));
    };

    const icons = [
        {
            icon: <FaHeartbeat />, // size={380}
            top: "20%",
            left: "10%",
            rotate: "-15deg",
        },
        {
            icon: <FaWeightScale />, // size={330}
            top: "48%",
            right: "5%",
            rotate: "25deg",
        },
        {
            icon: <FaChartLine />, // size={350}
            top: "100%",
            left: "15%",
            rotate: "-30deg",
        },
    ];

    return (
        <>
            <Navbar />

            <div className={"healthmate__login--body"}>
                <BgImages icons={icons} />

                <Toaster richColors={true} expand={false} position={"top-center"} theme={"dark"}/>

                <p className={"healthmate__login--title"}>
                    Login to your <span>HealthMate</span> Account
                </p>

                <p className={"healthmate__login--memo"}>
                    <span>Welcome back! Ready to continue your health journey?</span> <br />
                    Log in to track your progress and stay on top of your health goals.
                </p>

                <div className={"healthmate__login--alternative"}>
                    <button disabled={isButtonDisabled} onClick={loginViaGoogle}>
                        <FaGoogle />
                    </button>

                    <button disabled={isButtonDisabled} onClick={loginViaGithub}>
                        <FaGithub />
                    </button>
                </div>

                <div className={"healthmate__login--divider"}></div>

                <AuthDetails loggedIn={setIsButtonDisabled} displayNameVisible={true}/>

                {!isButtonDisabled && (
                    <>
                        <form>
                            <div className={"healthmate__login--information"}>
                                <input
                                    aria-label={"Input Email"}
                                    type={"email"}
                                    placeholder={"Enter your Email"}
                                    className={"healthmate__login--input"}
                                    onChange={(e) => setEmail(e.target.value)}
                                />

                                <div className={"healthmate__password--container"}>
                                    <input
                                        aria-label={"Input Password"}
                                        type={passwordVisible ? 'text' : 'password'}
                                        placeholder={"Enter your Password"}
                                        className={"healthmate__login--password"}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />

                                    <button
                                        aria-label={"Password Visible"}
                                        className={"healthmate__password--visible"}
                                        type={'button'}
                                        onClick={() => setPasswordVisible(!passwordVisible)}
                                    >
                                        {passwordVisible ? <BsEyeFill fill={'#b7b7b7'}/> : <BsEyeSlashFill fill={'#b7b7b7'}/>}
                                    </button>
                                </div>
                            </div>
                        </form>

                        {errorMessage && (
                            <div className={"fields--errors__container"}>
                                <p className="errors">
                                    <span><IoWarning fill={'#fd4040'}/></span> {errorMessage}
                                </p>
                            </div>
                        )}

                        <button
                            className={"healthmate__login--button"}
                            aria-label={"Log In"}
                            type={"submit"}
                            disabled={isButtonDisabled || !email || !password}
                            onClick={loginSubmit}
                        >
                            Login
                        </button>

                        <div className={"healthmate__login--support"}>
                            <a href={"/signup"} aria-label={"Make an account"} className={"healthmate__login--links"}>
                                Don't have a HealthMate account?
                                <span>
                                Register now
                            </span>
                            </a>

                            <a href={"/reset-password"} aria-label={"Reset Password"} className={"healthmate__login--links"}>
                                Forgot your password?
                                <span>
                                    Reset it
                                </span>
                            </a>
                        </div>
                    </>
                )}
            </div>

            <Footer />
        </>
    );
};

export default AccountLogin;