import React from "react";
import "./styles.css";

interface IconProps {
    icon: React.ReactElement;
    top?: string;
    left?: string;
    right?: string;
    rotate?: string;
}

interface BgImagesProps {
    icons: IconProps[];
}

const BgImages: React.FC<BgImagesProps> = ({ icons }) => {
    return (
        <div className="background-icon__container">
            {icons.map((iconProps, index) =>
                React.cloneElement(iconProps.icon, {
                    key: index,
                    className: "background-icon",
                    style: {
                        position: "absolute",
                        top: iconProps.top || "0%",
                        left: iconProps.left,
                        right: iconProps.right,
                        transform: `rotate(${iconProps.rotate || "0deg"})`,
                    },
                })
            )}
        </div>
    );
};

export default BgImages;