import React, { MouseEventHandler, ReactNode } from 'react';
import './style.css';

const SubmitButton = (
    props: {
        onClick?: MouseEventHandler<HTMLButtonElement>
        type?: "submit" | "reset" | "button"
        disabled?: boolean;
        title: ReactNode;
    }) => {
    return (
        <div className={"buttons"}>
            <button
                aria-label={"Submit"}
                className={"buttons__submit"}
                onClick={props.onClick}
                type={props.type}
                disabled={props.disabled}
            >
                {props.title}
            </button>
        </div>
    );
};

export default SubmitButton;